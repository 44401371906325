import { useEffect } from 'react';
import { dispatchCustomEvent } from '@bsd/ui-utils/tracking';
import useSessionId from 'hooks/useSessionId';

export default function useTrackPage(screenName) {
  const sessionID = useSessionId();

  useEffect(() => {
    const detail = {
      page: {
        pageInfo: {
          language: 'en/us',
          screenName,
        },
        category: {
          businessType: 'busn_smb',
          designType: 'responsive',
          primaryCategory: 'Commercial Sign In',
          siteType: 'selfservice',
          subCategory1: 'Password Reset',
        },
        codebase: {
          name: 'bsd-password-reset-ui',
          releaseVersion: `${process.env.REACT_APP_UI_VERSION}`,
        },
        affiliate: {
          channel: 'web',
          name: 'comcast',
        },
        siteInfo: {
          server: 's3',
          sessionID: `${sessionID}`,
          visitorID: `${sessionID}`,
        },
      },
      user: [
        {
          profile: [
            {
              profileInfo: {
                authenticationType: 'unauthenticated',
              },
            },
          ],
        },
      ],
    };
    dispatchCustomEvent(detail, true);
  }, [screenName, sessionID]);
}
