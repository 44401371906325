import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import initialConfig from 'config';

export const ApplicationContext = React.createContext();

// Used for debugging
ApplicationContext.displayName = 'ApplicationContext';

export const ApplicationProvider = ({ children }) => {
  return (
    <ApplicationContext.Provider value={initialConfig}>
      {children}
    </ApplicationContext.Provider>
  );
};

ApplicationProvider.propTypes = {
  children: PropTypes.node,
};

export function useAppState() {
  const appState = useContext(ApplicationContext);

  if (appState === undefined) {
    throw new Error('useAppState must be used within a ApplicationProvider');
  }

  const { featureFlags, redirectLinks } = appState;

  return { featureFlags, redirectLinks };
}

export default ApplicationContext;
