import React from 'react';
import { Link, Text } from 'bsd-react-ui-kit';

const PageNotFound = () => {
  const message = "We're sorry, this page does not exist.";

  return (
    <div className="bsd-page bsd-page--not-found">
      <Text element="h1" modifier="bold" type="display">
        Page not found
      </Text>
      <Text element="p">{message}</Text>
      <Text element="p">
        Please visit the <Link to="/">My Account</Link> homepage or search{' '}
        <Link external to="http://businesshelp.comcast.com/help-and-support">
          Help &amp; Support
        </Link>{' '}
        to find what you are looking for.
      </Text>
    </div>
  );
};

export default PageNotFound;
