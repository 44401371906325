import devConfig from 'config/dev';
import qaConfig from 'config/qa';
import intConfig from 'config/int';
import pqaConfig from 'config/pqa';
import stgVaConfig from 'config/stg-va';
import stgOrConfig from 'config/stg-or';
import prodVaConfig from 'config/prod-va';
import prodOrConfig from 'config/prod-or';
import localConfig from 'config/local';
const envMap = {
  localhost: 'local',
  'password-reset-dev.np.digital.business.comcast.com': 'dev',
  'password-reset-qa.np.digital.business.comcast.com': 'qa',
  'password-reset-int.np.digital.business.comcast.com': 'int',
  'password-reset-pqa.np.digital.business.comcast.com': 'pqa',
  'business.stg.comcast.com': 'stg-va',
  'password-reset-stg-va.np.digital.business.comcast.com': 'stg-va',
  'password-reset-stg-or.np.digital.business.comcast.com': 'stg-or',
  'business.comcast.com': 'prod-va',
  'password-reset-prod-va.digital.business.comcast.com': 'prod-va',
  'password-reset-prod-or.digital.business.comcast.com': 'prod-or',
};

const currentEnvironment = () => envMap[window.location.hostname];

export const getConfig = () => {
  switch (currentEnvironment()) {
    case 'dev':
      return devConfig;
    case 'qa':
      return qaConfig;
    case 'int':
      return intConfig;
    case 'pqa':
      return pqaConfig;
    case 'stg-va':
      return stgVaConfig;
    case 'stg-or':
      return stgOrConfig;
    case 'prod-va':
      return prodVaConfig;
    case 'prod-or':
      return prodOrConfig;
    default:
      return localConfig;
  }
};

export default getConfig();
