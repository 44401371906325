import React from 'react';
import { Sprite as UIKitSprite } from 'bsd-react-ui-kit';

import ComcastBusiness from 'components/Sprite/ComcastBusiness';

export const Sprite = () => (
  <UIKitSprite>
    <ComcastBusiness />
  </UIKitSprite>
);

export default Sprite;
