import { dispatchCustomEvent } from '@bsd/ui-utils/tracking';

export const createSelfServiceTracking = (transaction, page) => {
  return {
    eventMethod: 'self-ServiceTransaction',
    eventAction: 'self-ServiceTransaction',
    eventName: 'self service transaction',
    eventPage: `busn_smb|selfservice|Commercial Sign In|Password Reset|${page}`,
    transaction: {
      attributes: {
        selfServiceTransactions: transaction,
      },
    },
  };
};

export const createErrorTracking = (errorMessage, errorAction, page) => {
  return {
    eventMethod: 'send-Error',
    eventAction: errorAction,
    eventPage: page,
    eventName: 'selfservice transaction error',
    attributes: {
      errorMessage,
    },
  };
};

export const events = {
  RESET_PASSWORD_SUCCESS: () =>
    createSelfServiceTracking(
      'account:password reset:success',
      'reset password'
    ),
  RESET_PASSWORD_FAILURE: () =>
    createSelfServiceTracking(
      'account:password reset:failure',
      'reset password'
    ),
  RESET_PASSWORD_ERROR: (errorMessage) =>
    createErrorTracking(
      getErrorCodeFromMessage(errorMessage),
      'error resetting password',
      'reset password'
    ),
};

export const trackEvent = (event) => {
  dispatchCustomEvent({ eventInfo: event }, false);
};

function getErrorCodeFromMessage(errorMessage) {
  return errorMessage !== undefined && errorMessage[0].code
    ? errorMessage[0].code
    : 'unable to set error code from response';
}
