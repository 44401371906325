import { useEffect, useState } from 'react';

const useScreenSize = () => {
  //from bsd-ui-stylekit/dist/variables.scss
  const smallWidth = 480;

  const [size, setSize] = useState('');
  const resize = () => {
    if (window.innerWidth <= smallWidth) {
      setSize('sm');
    } else {
      setSize('lg');
    }
  };
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize, false);
  }, []);
  return size;
};

export default useScreenSize;
