import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { hot } from 'react-hot-loader/root';

import AppContainer from 'containers/AppContainer';

import useFido from 'hooks/useFido';

import { getConfig } from 'config';

export const App = () => {
  const isFidoReady = useFido();

  useEffect(() => {
    if (document.getElementById('bsd-global-nav')) return;

    const { globalNavConfig } = getConfig();
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', globalNavConfig.scriptUrl);
    scriptTag.setAttribute('id', 'bsd-global-nav');
    scriptTag.setAttribute('data-application-name', 'PasswordReset');
    scriptTag.setAttribute(
      'data-application-root-path',
      '/password-reset/forgotpassword'
    );
    scriptTag.setAttribute('data-application-type', 'SERVER');
    scriptTag.setAttribute(
      'data-flow-header',
      '{ "closeButtonUrl":"https://business.comcast.com"}'
    );
    scriptTag.setAttribute(
      'data-components',
      '[{ "type": "FLOW_HEADER", "elementId": "bsd-global-nav-header"}, { "type": "FLOW_FOOTER", "elementId": "bsd-global-nav-footer"}]'
    );
    scriptTag.setAttribute('data-environment', globalNavConfig.environment);
    document.body.appendChild(scriptTag);
  }, []);

  return isFidoReady && <Route component={AppContainer} />;
};

export default hot(App);
