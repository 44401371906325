import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formikForm, FormField } from 'bsd-form-ui-kit';
import { clickTracking } from '@bsd/ui-utils/tracking';
import {
  Button,
  Panel,
  Text,
  BodyCopy,
  FlowButtons,
  ReCaptcha,
  Dialog,
  Link,
} from 'bsd-react-ui-kit';
import { useAppState } from 'context/application';
import validation from '@bsd/ui-utils/validation';
import content from './content';
import useScreenSize from 'hooks/useScreenSize';

const EmailForm = ({ submitCount, setCaptchaValue, errors, captchaValue }) => {
  const { redirectLinks } = useAppState();
  const baseClass = 'bsd-create-email';
  const showDialog = submitCount > 0 && (errors.emailAddress || !captchaValue);
  const showError = errors.captcha && submitCount !== 0 && !captchaValue;
  return (
    <div className={baseClass}>
      <Text type="display">{content.title}</Text>
      <Dialog noClose show={showDialog} title={content.validation.dialog} />
      <Panel>
        <BodyCopy>{content.formHeader}</BodyCopy>
        <div className={`${baseClass}-input-row`}>
          <div className={`${baseClass}-email-input`}>
            <FormField
              id="emailAddress"
              input={{
                name: 'emailAddress',
              }}
              label={content.emailLabel}
              name="emailAddress"
              type="text"
            />
          </div>
        </div>
        <Link
          data-tracking={clickTracking(content.forgotEmail)}
          to={redirectLinks.forgotEmailUrl}
          target="_blank"
          rel="noreferrer"
          underline
        >
          {content.forgotEmail}
        </Link>

        <div className={`${baseClass}-captcha`}>
          <div className={showError && `${baseClass}-captcha-component-error`}>
            {useScreenSize() === 'sm' && (
              <ReCaptcha
                onChange={(key) => setCaptchaValue(key)}
                size="compact"
              />
            )}
            {useScreenSize() === 'lg' && (
              <ReCaptcha
                classname={`${baseClass}-recaptcha`}
                onChange={(key) => setCaptchaValue(key)}
              />
            )}
          </div>
        </div>
        <div className={showError && `${baseClass}-captcha-text-error`}>
          {errors.captcha && submitCount !== 0 && !captchaValue
            ? content.validation.captcha.toUpperCase()
            : ''}
        </div>
      </Panel>

      <FlowButtons alignment="end">
        <Button
          modifier="primary"
          type="submit"
          data-tracking={clickTracking(content.submitButton)}
        >
          {content.submitButton}
        </Button>
      </FlowButtons>
    </div>
  );
};

EmailForm.propTypes = {
  captchaValue: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  setCaptchaValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
};

const EnterEmailAddress = ({ onSubmit, ...props }) => {
  const [captchaValue, setCaptchaValue] = useState('');
  const formikProps = {
    initialValues: {
      emailAddress: '',
      captchaValue: '',
    },
    initialErrors: {
      emailAddress: '',
      captchaValue: '',
    },
    validate: (values) => {
      const errors = {};
      const isInvalidEmailFormat = validation.email(values.emailAddress);
      const criteria = isInvalidEmailFormat === undefined;

      if (!values.emailAddress) {
        errors.emailAddress = content.validation.required;
      } else if (!criteria) {
        errors.emailAddress = content.validation.invalid;
      }
      if (!captchaValue) {
        errors.captcha = content.validation.captcha;
      }
      values.captchaValue = captchaValue;
      return errors;
    },
    onSubmit,
  };

  return formikForm(
    EmailForm,
    formikProps
  )({ ...props, setCaptchaValue, captchaValue });
};

export default EnterEmailAddress;
