import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from 'components/App';
import './main.scss';

import { ApplicationProvider } from 'context/application';
import Sprite from 'components/Sprite/Sprite';

ReactDOM.render(
  <BrowserRouter>
    <ApplicationProvider>
      <Sprite />
      <App />
    </ApplicationProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

export default App;
