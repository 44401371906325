import { useCallback, useState } from 'react';
import { resetPassword } from 'utils/requests';

const useSubmitNewPassword = (inviteId) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const submit = useCallback(
    async ({ password }) => {
      setSubmitting(true);
      try {
        const response = await resetPassword(password, inviteId);
        if (response.status === 204) {
          setSubmitted(true);
        } else {
          setError('Invalid response');
        }
      } catch (e) {
        setError(e);
      } finally {
        setSubmitting(false);
      }
    },
    [inviteId, setSubmitting, setSubmitted, setError]
  );

  return { submit, submitting, submitted, error };
};

export default useSubmitNewPassword;
