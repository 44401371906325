/* eslint-disable import/no-anonymous-default-export */
const loggingBaseUrl =
  'https://business.comcast.com/account/core-gateway/logging-api';
const loggingApiKey = 'rIYwDjmhEzaYwpKjeMBRF6Lu1i05OzAE1UIE76RB';

export default {
  featureFlags: {
    useNewForgotPasswordEnabled: true,
  },
  fidoConfig: {
    headers: {
      'tracking-id': '',
      'x-api-key': 'rIYwDjmhEzaYwpKjeMBRF6Lu1i05OzAE1UIE76RB',
    },
    dataApiConfig: {
      logging: {
        baseUrl: loggingBaseUrl,
        sensitiveFields: ['newPassword'],
      },
      passwordReset: {
        baseUrl:
          'https://password-reset-bff.prod-va.api-business.comcast.com/passwordreset',
        sensitiveFields: ['newPassword'],
      },
    },
  },
  redirectLinks: {
    forgotEmailUrl:
      'https://business.comcast.com/myaccount/Registration/ForgotEmail',
  },
  loggerConfig: {
    appSettings: {
      applicationId: 'ResetPassword',
      apiKey: loggingApiKey,
      loggingEndpoint: {
        url: '/event',
        baseURL: loggingBaseUrl,
      },
    },
    environment: 'prod',
    visitorSessionId: '',
  },
  sessionDurationInMinutes: 20,
  globalNavConfig: {
    scriptUrl:
      'https://bsd-global-nav-ui-prod-va.digital.business.comcast.com/globalNav.js',
    environment: 'prod',
  },
};
