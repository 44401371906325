import React from 'react';
import { Spinner, ServiceError } from 'bsd-react-ui-kit';
import useSubmitEmail from 'hooks/useSubmitEmail';
import EmailConfirmation from './EmailConfirmation/EmailConfirmation';
import EnterEmailAddress from './EnterEmailAddress/EnterEmailAddress';
import { useAppState } from 'context/application';

const ForgotPassword = () => {
  const { submit, submitting, error, submitted, confirmedEmailAddress } =
    useSubmitEmail();
  const { featureFlags } = useAppState();
  const flagsStatus = featureFlags.useNewForgotPasswordEnabled;

  if (submitting) {
    return <Spinner show={true} />;
  }

  if (error) {
    return <ServiceError />;
  }

  return submitted && flagsStatus === true ? (
    <EmailConfirmation email={confirmedEmailAddress} />
  ) : (
    <EnterEmailAddress onSubmit={submit} />
  );
};

export default ForgotPassword;
